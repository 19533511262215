<template>
  <div class="company-honor-css-all">
    <LeftTitle title="企业荣誉"/>
    <div class="company-honor-css" :style="`height:${swiperHeight}`">
      <swiper
        :slidesPerView="swiperRowViews"
        :grid="{ rows: swiperRows}"
        :spaceBetween="30"
        :pagination="{clickable: true}"
        :modules="modules"
        navigation
        class="animated bounceInLeft"
        :style="`height:${swiperHeight}`"
      >
        <swiper-slide v-for="honorVo in honorVoList">
          <div>
            <img v-lazy="honorVo.filePath" :alt="honorVo.honorName">
            <p>{{ honorVo.honorName }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper.css';
import 'swiper/modules/navigation.css';
import 'swiper/modules/grid.css';

// import required modules
import {Navigation, Grid} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/swiper-vue.mjs';
import {getHonorList} from '@/api/honor'
import {onMounted, ref} from "vue";
import LeftTitle from "@/components/library/base-left-title.vue";

export default {
  components: {
    LeftTitle,
    Swiper,
    SwiperSlide,
  },
  props:{
    swiperRows:{
      type:Number,
      default:2
    },
    swiperRowViews:{
      type:Number,
      default: 3
    },
    swiperHeight:{
      type:String,
      default:"700px"
    }
  },
  setup() {
    // 使用 ref 创建响应式的数据对象
    const honorVoList = ref(null);
    // 异步获取数据
    const fetchHonorList = async () => {
      try {
        const response = await getHonorList();
        honorVoList.value = response.data; // 将获取的数据赋值给 ref
      } catch (error) {
      }
    };
    // 在组件挂载时调用获取数据的方法
    onMounted(fetchHonorList);
    return {
      honorVoList,
      modules: [Grid, Navigation]
    };
  },
};
</script>

<style scoped lang="less">
.company-honor-css-all {
  padding-top: 10px;
  width: 100%;

  .company-honor-css {
    margin-left: 10vh;
    margin-right: 10vh;

    .swiper {
      width: 100%;
      --swiper-navigation-color: #000;
      --swiper-navigation-size: 25px;
    }

    .swiper-slide {
      div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        img {
          height: 90%;
        }

        p {
          height: 20%;
          font-size: 12px;
          color: #000;
        }
      }
    }

  }
}
</style>
